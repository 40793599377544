import React, { useEffect, useState } from 'react';
import { FiEdit2, FiCopy, FiMail, FiPhone, FiLock, FiFileText } from 'react-icons/fi';
// import { FaWhatsapp, FaTelegramPlane, FaTwitter } from 'react-icons/fa';
import profilePic from "../assets/user.png";
import KycProgress from "../utils/kycProgress";
// import dollerCoin from "../assets/dollercoin.jpg";
import authFetch from '../utils/authFetch';
import PuffLoader from "react-spinners/PuffLoader";
import { useNavigate } from 'react-router-dom';
import { Link } from 'react-router-dom';
import useProfileStore from '../store/profileStore';
const Profile = () => {
    const { setGlobalProfile } = useProfileStore();
    const navigate = useNavigate()
    const [profile, setProfile] = useState(null);
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        fetchProfile();
    }, []);

    const fetchProfile = async () => {
        try {
            const response = await authFetch('/api/v1/user/profile', {
                method: 'GET',
            });

            if (response) {
                setProfile(response.data);
                setGlobalProfile(response?.data)
            } else {
                navigate('/login')
            }
        } catch (error) {
            console.error('Error fetching profile:', error);
            navigate('/login')
        } finally {
            setLoading(false);
        }
    };

    if (loading || !profile) {
        return (
            <div style={styles.loaderContainer}>
                <PuffLoader color="#28d849" size={60} />
            </div>
        );
    }

    return (
        <div className="flex flex-col items-center w-full h-full p-0 space-y-4">
            {/* Profile Header */}
            <div className="w-full bg-white p-4 flex items-center">
                <img
                    src={profilePic}
                    alt="Profile"
                    className="h-20 w-16 border border-gray-100"
                />
                <div className="ml-4">
                    <h2 className="text-lg text-gray-600 font-bold">Your Profile</h2>
                    <p className="text-gray-500 flex items-center">
                        <FiMail className="mr-2" /> {profile.email}
                    </p>
                    {profile.isPhoneAdded ? (
                        <p className="text-gray-500 flex items-center">
                            <FiPhone className="mr-2" />
                            {profile.phone}
                        </p>
                    ) : (
                        <Link
                            to={'/addPhone'}
                            className="text-blue-500 text-sm font-semibold text-left w-full flex items-center">
                            <FiPhone className="mr-2" /> Add your phone
                        </Link>
                    )}
                    <div className="flex flex-col space-y-2 mt-2 w-full">
                        <Link
                            to={'/change-password'}
                            className="text-blue-500 text-sm font-semibold text-left w-full flex items-center">
                            <FiLock className="mr-2" /> Change Password
                        </Link>
                        {/* <button className="text-blue-500 text-sm font-semibold text-left w-full flex items-center">
                            <FiFileText className="mr-2" /> Generate TDS Report
                        </button> */}
                    </div>
                </div>
            </div>

            {/* KYC Status */}
            <div className="w-full bg-slate-200 rounded-lg shadow-md p-4 space-y-6">
                <div>
                    <h3 className="text-md font-bold text-gray-800">KYC STATUS</h3>
                    <p className="text-gray-600 mt-2 text-sm">
                        {profile.isKycCompleted
                            ? "Your KYC has been completed"
                            : "To enable buy & sell, complete your Basic KYC"}
                    </p>
                </div>

                {!profile.isKycCompleted && (
                    <Link
                        to={profile.isPhoneAdded ? '/kyc' : '/addPhone'}
                        className="w-full mt-4 bg-blue-600 text-white py-3 rounded-lg flex justify-center items-center text-lg font-bold">
                        Complete Basic KYC
                        <FiEdit2 className="ml-3 text-xl" />
                    </Link>
                )}

                <KycProgress />

                {/* Buy & Sell Limit */}
                <div className="w-full bg-white rounded-lg shadow-md p-4 space-y-6">
                    <div className="flex justify-between">
                        <p className="text-black text-md font-semibold">Buy limit:</p>
                        <p className="text-gray-500 text-md font-semibold">₹0 / month</p>
                    </div>
                    <div className="flex justify-between mt-2">
                        <p className="text-black text-md font-semibold">Sell limit:</p>
                        <p className="text-gray-500 text-md font-semibold">₹0 / month</p>
                    </div>
                </div>
            </div>

            {/* Referral Earnings */}
            {/* <div className="w-full bg-white rounded-lg shadow-md p-4 space-y-4">

                <div className="flex justify-between items-start">
                    <div>
                        <h3 className="text-md font-bold text-gray-800">My Referral Earnings</h3>
                        <div className="flex items-center mt-1">
                            <span className="text-green-500 text-xl mr-1">$</span>
                            <span className="text-gray-900 text-2xl font-semibold">0</span>
                            <span className="text-gray-700 ml-2">USDT</span>
                        </div>
                    </div>
                    <div className="ml-4 flex-shrink-0">
                        <img src={dollerCoin} alt="Earnings Icon" className="h-24 w-24 object-contain" />
                    </div>
                </div>
                <p className="text-gray-800 mt-4 text-md">
                    Refer USDT Marketplace to contacts, earn when they transact
                </p>
                <a href="#" className="text-blue-600 text-md font-semibold mt-2 inline-block">
                    Learn more
                </a>
                <div className="relative mt-4 p-3 bg-gray-100 rounded-lg flex items-center">
                    <input
                        type="text"
                        value="https://usdtmarketplace.m...l/625737?appId=1"
                        readOnly
                        className="bg-transparent text-gray-600 font-bold w-full outline-none"
                    />
                    <button className="ml-2">
                        <FiCopy className="text-blue-600 text-lg" />
                    </button>
                </div>

                <div className="flex justify-between bg-green-600 mt-4 p-2 rounded-lg border border-green-700">
                    <button className="flex-1 flex justify-center items-center">
                        <FaWhatsapp className="h-6 w-6 text-white" />
                    </button>
                    <div className="w-1 h-8 bg-black mx-2"></div>
                    <button className="flex-1 flex justify-center items-center">
                        <FaTelegramPlane className="h-6 w-6 text-white" />
                    </button>
                    <div className="w-1 h-8 bg-black mx-2"></div>
                    <button className="flex-1 flex justify-center items-center">
                        <FaTwitter className="h-6 w-6 text-white" />
                    </button>
                </div>
            </div> */}
        </div>
    );
};

const styles = {
    loaderContainer: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        height: '50vh',
    },
};

export default Profile;
