import React, { useState, useEffect } from 'react';
import { FaQuestionCircle } from 'react-icons/fa';
import UseBuySelectorStore from '../store/BuySelector';
import authFetch from '../utils/authFetch';
import { showToast } from '../utils/toast';
import { useNavigate } from 'react-router-dom';
import useProfileStore from '../store/profileStore';
const WalletAddress = () => {
    const [isOwner, setIsOwner] = useState('yes');
    const navigate = useNavigate()
    const [showTooltip, setShowTooltip] = useState(false); // Tooltip state
    const { globalProfile } = useProfileStore()
    const { globalNetwork, globalCoin, globalCurrency, globalYouSend, globalYouGet, globalWalletAddress, setGlobalWalletAddress, globalQuoteData, globalPaymentMethod } = UseBuySelectorStore()
    const handleAddressChange = (e) => {
        setGlobalWalletAddress(e.target.value);
    };

    const handleOwnershipChange = (e) => {
        setIsOwner(e.target.value);
    };
    const handleProceed = async () => {
        try {
            // Check if the user is logged in and KYC status
            if (!globalProfile) {
                navigate("/login");
                return;
            }
            if (!globalProfile?.isPhoneAdded) {
                navigate("/addPhone");
                return;
            }

            if (!globalProfile?.isKycCompleted) {
                navigate("/before-kyc");
                return;
            }
            let body = {
                fromCurrency: globalCurrency?.fiatSymbol,
                toCurrency: globalCoin?.coin.toUpperCase(),
                chain: globalNetwork.chainSymbol,
                paymentMethodType: globalPaymentMethod,
                depositAddress: globalWalletAddress,
                fromAmount: globalYouSend,
                toAmount: globalYouGet,
                rate: globalQuoteData?.rate
            }
            const response = await authFetch(`/api/v1/user/createOnrampTransaction`, {
                method: 'POST',
                body: body,
                headers: {
                    'Content-Type': 'application/json',
                }
            });
            if (response?.code !== 200) {
                showToast(response?.error, "error")
                return
            }
            showToast(response?.message, "success")
            const orderCreatedResp = response?.data
            orderCreatedResp.amount = globalYouSend
            globalPaymentMethod === "UPI" ? navigate("/upi-checkout", { state: orderCreatedResp }) : navigate("/imps-checkout", { state: orderCreatedResp })
        } catch (error) {
            console.error('Error fetching handleProceed:', error);
        }
    };

    useEffect(() => {
        // Cleanup function to reset the states when the component unmounts
        return () => {
            setGlobalWalletAddress(''); // Clear walletAddress
            setIsOwner('yes');    // Reset ownership to default or any other value
        };
    }, []); // Empty dependency array ensures this runs on unmount

    return (
        <div className='p-3 space-y-16  w-full'>
            <div className="space-y-4">
                <div className="flex items-center space-x-4">
                    <h1 className="text-lg font-bold">Enter Wallet Address</h1>
                    <div
                        className="relative flex items-center"
                        onMouseEnter={() => setShowTooltip(true)}
                        onMouseLeave={() => setShowTooltip(false)}
                    >
                        <FaQuestionCircle className="text-gray-400 text-lg cursor-pointer" />
                        {/* Tooltip */}
                        {showTooltip && (
                            <div className="absolute left-1/2 transform -translate-x-1/2 top-full mt-2 w-52 p-4 bg-gray-800 text-white text-sm rounded-lg shadow-lg z-10">
                                <div className="relative">
                                    <div className="absolute -top-2 left-1/2 transform -translate-x-1/2 w-3 h-3 bg-gray-800 rotate-45"></div>
                                    <p className='text-xs mt-2'>This is the address where you want to send your crypto to.</p>
                                    <p className="text-xs mt-2">This may be a wallet address (like MetaMask) or an exchange (like Binance).</p>
                                    <p className="text-xs mt-2">Addresses may vary based on the network. Please select the correct network before copying your address.</p>
                                </div>
                            </div>
                        )}
                    </div>
                    {/* <FaQuestionCircle className="text-gray-400 text-lg" /> */}
                </div>

                {/* Wallet Address Input */}
                <div>
                    <textarea
                        value={globalWalletAddress}
                        onChange={handleAddressChange}
                        placeholder={`${globalNetwork?.chainSymbol?.toUpperCase()} network address`}
                        className="w-full border-2 rounded-lg p-3 text-lg font-semibold text-gray-500 placeholder-gray-400 focus:outline-none focus:border-green-500"
                        rows="2"
                    />
                </div>

                {/* Network and Token Info */}
                <div className="p-4 bg-yellow-50 rounded-lg border">
                    <p className="text-gray-600 font-small mb-2 text-xs">Ensure the Address supports the network & token:</p>
                    <div className="flex space-x-8 items-center">
                        <div className="space-y-0">
                            <p className="text-xs text-gray-600">Network:</p>
                            <p className="font-medium text-sm">{globalNetwork?.chainSymbol?.toUpperCase()}</p>
                        </div>
                        <div className="space-y-0">
                            <p className="text-xs text-gray-600">Token:</p>
                            <p className="font-medium text-sm">{globalCoin?.coin?.toUpperCase()}</p>
                        </div>
                    </div>
                </div>

                {/* Ownership Selection */}
                <div className="space-y-3">
                    <p className="font-semibold">Does this wallet address belong to you?</p>
                    <div className="flex justify-between space-x-2">
                        <label
                            className={`flex items-center justify-left w-full h-12 rounded-lg p-4 cursor-pointer bg-gray-100 border border-gray-200`}
                        >
                            <input
                                type="radio"
                                name="walletOwnership"
                                value="yes"
                                checked={isOwner === 'yes'}
                                onChange={handleOwnershipChange}
                                className="hidden" // Hide the actual radio button
                            />
                            <span className={`flex items-center ${isOwner === 'yes' ? 'text-black' : 'text-gray-500'}`}>
                                <span
                                    className={`form-radio rounded-full h-5 w-5 mr-2 flex items-center justify-center ${isOwner === 'yes' ? 'bg-green-500' : 'bg-gray-300'
                                        }`}
                                >
                                    {isOwner === 'yes' && (
                                        <span className="bg-white h-2.5 w-2.5 rounded-full"></span>
                                    )}
                                </span>
                                <span className="font-semibold">Yes</span>
                            </span>
                        </label>

                        <label
                            className={`flex items-center justify-left w-full h-12 rounded-lg p-4 cursor-pointer bg-gray-100 border border-gray-200 `}
                        >
                            <input
                                type="radio"
                                name="walletOwnership"
                                value="no"
                                checked={isOwner === 'no'}
                                onChange={handleOwnershipChange}
                                className="hidden" // Hide the actual radio button
                            />
                            <span className={`flex items-center ${isOwner === 'no' ? 'text-black' : 'text-gray-500'}`}>
                                <span
                                    className={`form-radio rounded-full h-5 w-5 mr-2 flex items-center justify-center ${isOwner === 'no' ? 'bg-green-500' : 'bg-gray-300'
                                        }`}
                                >
                                    {isOwner === 'no' && (
                                        <span className="bg-white h-2.5 w-2.5 rounded-full"></span>
                                    )}
                                </span>
                                <span className="font-semibold">No</span>
                            </span>
                        </label>
                    </div>
                    {
                        isOwner === "no" && (
                            <div className="bg-red-50 border border-red-200 rounded-lg p-4 mt-4">
                                <p className="text-red-600 font-semibold text-sm">
                                    For security, we allow addresses you own.
                                </p>
                                <p className="text-red-600 text-xs">
                                    Please use a wallet address that belongs to you instead.
                                </p>
                            </div>
                        )
                    }
                </div>


            </div>
            <div>
                {/* Proceed Button */}
                <button
                    onClick={handleProceed}
                    className={`w-full py-4 rounded-lg text-md font-bold transition duration-200 ${!globalWalletAddress || isOwner === 'no'
                        ? 'bg-green-300 text-white'
                        : 'bg-green-600 text-white hover:bg-green-700'
                        }`}
                    disabled={!globalWalletAddress || isOwner === 'no'}
                >
                    Proceed
                </button>
            </div>

        </div>

    );
};

export default WalletAddress;
