import React, { useState, useEffect } from 'react';
import authFetch from '../utils/authFetch';
import { showToast } from '../utils/toast';
import { useNavigate } from 'react-router-dom';

// Function to validate the account number
const validateBankAccount = (accountNumber) => {
    const regex = /^\d{9,18}$/;
    return regex.test(accountNumber);
};

const AddBankAccount = () => {
    const navigate = useNavigate();
    const [accountNumber, setAccountNumber] = useState('');
    const [confirmAccountNumber, setConfirmAccountNumber] = useState('');
    const [ifscCode, setIfscCode] = useState('');
    const [bankName, setBankName] = useState(''); // New state for Bank Name
    const [isFormValid, setIsFormValid] = useState(false);
    const [isSubmitting, setIsSubmitting] = useState(false);

    // Handle form validation and state
    const handleInputChange = (e) => {
        const { name, value } = e.target;

        // Allow only numeric input for account and confirm account number fields
        if ((name === 'accountNumber' || name === 'confirmAccountNumber') && !/^\d*$/.test(value)) {
            return; // If the input is not a number, do nothing
        }

        // Set the respective state values
        if (name === 'accountNumber') {
            setAccountNumber(value);
        } else if (name === 'confirmAccountNumber') {
            setConfirmAccountNumber(value);
        } else if (name === 'ifscCode') {
            setIfscCode(value);
        } else if (name === 'bankName') {
            setBankName(value);
        }
    };

    // Check form validation whenever inputs change
    const checkFormValidation = () => {
        if (
            validateBankAccount(accountNumber) && // Ensure account number is valid
            validateBankAccount(confirmAccountNumber) && // Ensure confirm account number is valid
            accountNumber === confirmAccountNumber && // Ensure both account numbers match
            ifscCode &&
            bankName
        ) {
            setIsFormValid(true);
        } else {
            setIsFormValid(false);
        }
    };

    // Call validation function whenever account numbers, IFSC, or bank name change
    useEffect(() => {
        checkFormValidation();
    }, [accountNumber, confirmAccountNumber, ifscCode, bankName]);

    // Handle form submission
    const handleSubmit = async () => {
        setIsSubmitting(true); // Disable the button during the request
        try {
            const response = await authFetch('/api/v1/offramp/addFiatAccount', {
                method: 'POST',
                body: {
                    bankName: bankName,
                    fiatAccount: accountNumber,
                    ifsc: ifscCode,
                },
            });

            if (response?.code === 200) {
                // Handle success (e.g., navigate to a different page or show a success message)
                showToast(response?.message, 'success');
                navigate('/bank');
            } else {
                // Handle error (e.g., show an error message)
                showToast(response?.error, 'error');
            }
        } catch (error) {
            console.error('Error adding bank account:', error);
        } finally {
            setIsSubmitting(false); // Re-enable the button
        }
    };

    return (
        <div className="flex flex-col h-[65vh] justify-between">
            <div className="flex-1 bg-white p-3 overflow-auto">
                {/* Form */}
                <div className="flex-1 space-y-6">
                    <p className="text-gray-600 text-sm">
                        Please add only <strong>your</strong> account, not others.
                    </p>

                    {/* Bank Account Number Input */}
                    <div className="space-y-2">
                        <label className="text-sm font-semibold text-gray-700">Bank Account Number</label>
                        <input
                            type="text"
                            name="accountNumber"
                            value={accountNumber}
                            onChange={handleInputChange}
                            className={`w-full border p-4 rounded-lg text-md focus:outline-none ${!validateBankAccount(accountNumber) && accountNumber
                                ? 'border-red-500 focus:border-red-600'
                                : 'border-gray-300 focus:border-green-600'
                                }`}
                            inputMode="numeric" // Mobile keyboards show numeric layout
                        />
                        {!validateBankAccount(accountNumber) && accountNumber && (
                            <p className="text-red-500 text-xs">Account number must be 9 to 18 digits long.</p>
                        )}
                    </div>

                    {/* Confirm Bank Account Number Input */}
                    <div className="space-y-2">
                        <label className="text-sm font-semibold text-gray-700">Confirm Bank Account Number</label>
                        <input
                            type="text"
                            name="confirmAccountNumber"
                            value={confirmAccountNumber}
                            onChange={handleInputChange}
                            className={`w-full border p-4 rounded-lg text-md focus:outline-none ${confirmAccountNumber !== accountNumber && confirmAccountNumber
                                ? 'border-red-500 focus:border-red-600'
                                : 'border-gray-300 focus:border-green-600'
                                }`}
                            inputMode="numeric" // Mobile keyboards show numeric layout
                        />
                        {confirmAccountNumber !== accountNumber && confirmAccountNumber && (
                            <p className="text-red-500 text-xs">Account numbers do not match.</p>
                        )}
                    </div>

                    {/* IFSC Code Input */}
                    <div className="space-y-2">
                        <label className="text-sm font-semibold text-gray-700">IFSC Code</label>
                        <input
                            type="text"
                            name="ifscCode"
                            value={ifscCode}
                            onChange={handleInputChange}
                            className="w-full border border-gray-300 p-4 rounded-lg text-md focus:outline-none focus:border-green-600"
                        />
                    </div>

                    {/* Bank Name Input */}
                    <div className="space-y-2">
                        <label className="text-sm font-semibold text-gray-700">Bank Name</label>
                        <input
                            type="text"
                            name="bankName"
                            value={bankName}
                            onChange={handleInputChange}
                            className="w-full border border-gray-300 p-4 rounded-lg text-md focus:outline-none focus:border-green-600"
                        />
                    </div>

                    {/* Verification Information */}
                    <p className="text-sm text-gray-500">
                        We will send ₹1 to your account to verify your bank.
                    </p>
                </div>
            </div>

            {/* Footer with Next Button */}
            <div className="w-full p-4 bg-white">
                <button
                    onClick={handleSubmit}
                    className={`w-full py-4 rounded-lg text-white font-semibold ${isSubmitting
                        ? 'bg-gray-200' // Button turns gray when submitting
                        : isFormValid
                            ? 'bg-green-500 hover:bg-green-600'
                            : 'bg-gray-200'
                        }`}
                    disabled={!isFormValid || isSubmitting}
                >
                    {isSubmitting ? 'Adding...' : 'Add Bank'}
                </button>
            </div>
        </div>
    );
};

export default AddBankAccount;
