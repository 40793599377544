import { useState, useEffect } from "react";
import "./index.css";
import axios from "axios";
import Widget from "./components/Widget";
import bgImage from './assets/background.jpeg';
import PuffLoader from "react-spinners/PuffLoader";
import Onramp from "./components/unlimit/Onramp";
import Offramp from "./components/unlimit/Offramp";
import Toast from './utils/toast'
import { FaArrowLeft } from 'react-icons/fa';

export default function App() {
  const [loading, setLoading] = useState(true);
  const [country, setCountry] = useState('');
  const [type, setType] = useState('onramp');
  useEffect(() => {
    const fetchIpAndCountry = async () => {
      try {
        const ipResponse = await axios.get('https://api.ipify.org?format=json');
        const userIp = ipResponse.data.ip;

        const geoResponse = await axios.get(`https://ipapi.co/${userIp}/json/`);
        setCountry(geoResponse.data.country_name);

        const params = new URLSearchParams(window.location.search);
        const typeParam = params.get('type');
        setType(typeParam || 'onramp');

        if (!typeParam) {
          window.history.replaceState(null, '', '/?type=onramp');
        }
      } catch (error) {
        console.error('Error fetching IP or country:', error);
      } finally {
        setLoading(false);
      }
    };

    fetchIpAndCountry();
  }, []);

  if (loading) {
    return (
      <div style={styles.loaderContainer}>
        <PuffLoader color="#28d849" size={60} />
      </div>
    );
  }

  return (
    <div className="App">
      <Toast />
      {country !== 'India' ? (
        type === 'onramp' ? (
          <Onramp />
        ) : type === 'offramp' ? (
          <Offramp />
        ) : null
      ) : (
        type === 'onramp' || type === 'offramp' ? (
          <div className="min-h-screen w-full relative">
            {/* Background visible only on desktop and tablet */}
            <div
              className="hidden md:flex justify-center items-center min-h-screen bg-cover bg-center w-full relative"
              style={{ backgroundImage: `url(${bgImage})`, flexDirection: 'column' }}
            >
              <div className="relative">
                {/* Back button - Flexbox is used to position it in the top-left corner of the widget */}
                <div className="absolute -top-9 left-2 cursor-pointer flex items-center space-x-2 p-2 z-10">
                  <FaArrowLeft
                    className="text-lg"
                    onClick={() => {
                      window.history.back();
                    }}
                  />
                  <span className="text-md font-semibold"></span>
                </div>

                {/* Widget - Centered on the screen */}
                <Widget type={type} />
              </div>
            </div>

            {/* Widget visible on mobile without background */}
            <div className="flex md:hidden justify-center items-center min-h-screen w-full bg-white">
              <Widget fullScreen={true} type={type} /> {/* Pass fullScreen as a prop */}
            </div>
          </div>

        ) : null
      )}
    </div>
  );

}

const styles = {
  loaderContainer: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    height: '100vh',
  },
};
