import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { FiRefreshCcw, FiTrash2 } from 'react-icons/fi';
import PuffLoader from 'react-spinners/PuffLoader'; // Loader
import authFetch from '../utils/authFetch'; // Assuming you have authFetch set up
import { showToast } from '../utils/toast';

const BankAccounts = () => {
  const [bankAccounts, setBankAccounts] = useState([]);
  const [loading, setLoading] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [selectedAccount, setSelectedAccount] = useState(null);

  // Function to fetch bank accounts from the API
  const fetchBankAccounts = async () => {
    setLoading(true); // Start loading
    try {
      const response = await authFetch('/api/v1/offramp/getAllFiatAccount', { method: 'GET' });
      if (response?.code === 200) {
        setBankAccounts(response.data); // Update state with fetched bank accounts
      }
    } catch (error) {
      console.error('Error fetching bank accounts:', error);
    } finally {
      setLoading(false); // Stop loading
    }
  };

  // Fetch bank accounts when component mounts
  useEffect(() => {
    fetchBankAccounts();
  }, []);

  const refreshData = () => {
    setLoading(true); // Start loading immediately
    setTimeout(() => {
      fetchBankAccounts(); // Fetch the bank accounts after 2 seconds
    }, 2000); // Delay of 2 seconds
  };

  // Handle delete functionality
  const handleDelete = (account) => {
    setSelectedAccount(account); // Set the account to be deleted
    setShowModal(true); // Show the confirmation modal
  };

  // Function to confirm and delete the account
  const confirmDelete = async () => {
    try {
      if (selectedAccount) {
        setLoading(true);
        const response = await authFetch(`/api/v1/offramp/deleteFiatAccount/${selectedAccount.id}`, {
          method: 'GET',
        });
        if (response?.code === 200) {
          showToast('Account deleted successfully', 'success');
          // Remove deleted account from the list
          setBankAccounts(bankAccounts.filter((account) => account.id !== selectedAccount.id));
        } else {
          showToast('Failed to delete account', 'error');
        }
      }
    } catch (error) {
      console.error('Error deleting account:', error);
    } finally {
      setLoading(false);
      setShowModal(false);
    }
  };

  // Function to close modal without deleting
  const closeModal = () => {
    setShowModal(false);
    setSelectedAccount(null); // Reset selected account
  };

  return (
    <div className="relative min-h-screen">
      {/* Main content with conditional blur effect when modal is shown */}
      <div className={`flex flex-col h-[65vh] justify-between transition duration-300 ${showModal ? 'filter blur-sm' : ''}`}>
        <div className="flex-1 bg-white p-6 overflow-auto">
          <div className="flex items-center justify-between">
            <h1 className="text-lg font-bold text-gray-800">Bank Accounts</h1>
            {/* Refresh Button */}
            <button
              onClick={refreshData}
              disabled={loading} // Disable button while loading
              className={`text-gray-500 text-xl cursor-pointer ${loading ? 'opacity-50 cursor-not-allowed' : ''}`}
            >
              <FiRefreshCcw />
            </button>
          </div>

          {/* Conditional Rendering for Loading and Bank Accounts */}
          {loading ? (
            <div className="flex justify-center items-center mt-10">
              <PuffLoader color="#3498db" size={60} />
            </div>
          ) : bankAccounts.length === 0 ? (
            <div className="mt-10 p-4 bg-white shadow-lg rounded-lg text-center">
              <p className="font-semibold text-gray-900">You've not added any bank accounts yet.</p>
              <p className="text-gray-500 mt-2">
                Please click on the button below to add a bank account.
              </p>
            </div>
          ) : (
            <div className="mt-10 space-y-5">
              {bankAccounts.map((account, index) => (
                <div key={index} className="p-4 bg-white shadow-lg rounded-lg space-y-3 relative">
                  <div className="flex justify-between items-center">
                    <p className="font-semibold text-gray-800 uppercase">{account?.bank_name}</p>
                    <div className="flex space-x-4">
                      {/* Delete Button */}
                      <button
                        onClick={() => handleDelete(account)}
                        className="flex items-center text-red-500 hover:text-red-600"
                      >
                        <FiTrash2 className="mr-1" />
                        <span>Delete</span>
                      </button>
                    </div>
                  </div>
                  <p className="text-gray-500 text-sm">Account Number: {account?.fiatAccount}</p>
                  <p className="text-gray-500 text-sm">IFSC: {account.ifsc}</p>
                </div>
              ))}
            </div>
          )}
        </div>

        {/* Add Bank Accounts Button */}
        <div className="w-full p-4 bg-white">
          <Link to="/add-bank">
            <button className="w-full bg-green-600 text-white py-3 rounded-lg text-md font-bold hover:bg-green-700 transition duration-200">
              Add Bank Accounts
            </button>
          </Link>
        </div>
      </div>

      {/* Confirmation Modal */}
      {showModal && (
        <div className="fixed inset-0 z-10 flex items-center justify-center bg-black bg-opacity-20">
          <div className="bg-white p-6 rounded-lg shadow-lg max-w-sm w-full">
            <h2 className="text-xl font-semibold text-gray-800 mb-4">Confirm Delete</h2>
            <p className="text-gray-600 mb-6">
              Are you sure you want to delete the bank account <strong>{selectedAccount?.bank_name?.toUpperCase()}</strong>?
            </p>
            <div className="flex justify-end space-x-4">
              <button
                className="bg-gray-200 text-gray-700 py-2 px-4 rounded hover:bg-gray-300"
                onClick={closeModal}
              >
                Cancel
              </button>
              <button
                className="bg-red-600 text-white py-2 px-4 rounded hover:bg-red-700"
                onClick={confirmDelete}
              >
                Delete
              </button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default BankAccounts;

