import React from 'react';
import { useLocation } from 'react-router-dom';
import { convertToIST } from '../utils/dateTimeFormat';
const HistoryDetail = () => {
    const location = useLocation()
    const { transaction, transactionType } = location?.state
    return (
        <div className="p-4 space-y-6">

            {/* Transaction Info */}
            <div className="space-y-6">
                <div className="space-y-3">
                    <p className="text-lg font-semibold">You sent {transaction?.fromCurrency} {transaction?.fromAmount} to {transaction?.toAmount} {transaction?.toCurrency}</p>
                    <p className="text-sm text-yellow-600 uppercase">Chain: {transaction?.chain}</p>
                </div>

                <div className="space-y-1">
                    <p className="text-md font-semibold">Status</p>
                    <p className={`text-sm ${transaction.status === "SUCCESS" ? 'text-green-500' : transaction.status === 'PENDING' || transaction.status === 'FIAT_DEPOSIT_RECEIVED' || transaction.status === 'TRADE_COMPLETED' || transaction.status === 'ON_CHAIN_INITIATED' ? 'text-yellow-500' : 'text-red-500'}`}>
                        {transaction.status}
                    </p>
                </div>
                {
                    transactionType === "buy" && (
                        <div className="space-y-1">
                            <p className="text-md font-semibold">Payment Method</p>
                            <p className="text-sm text-gray-700">{transaction?.paymentMethodType}</p>
                        </div>
                    )
                }

                <div className="space-y-1">
                    <p className="text-md font-semibold">Reference ID</p>
                    <p className="text-sm text-gray-700">{transaction?.reference_id}</p>
                </div>
                <div className="space-y-1">
                    <p className="text-md font-semibold">Transaction ID</p>
                    <p className="text-sm text-gray-700">{transaction?.transaction_id}</p>
                </div>
                {
                    transactionType === 'buy' ? (
                        <div className="space-y-1">
                            <p className="text-md font-semibold">Deposit Address</p>
                            <p className="text-sm text-gray-700">{transaction?.depositAddress}</p>
                        </div>
                    ) : (
                        <div className="space-y-1">
                            <p className="text-md font-semibold">Fiat Account </p>
                            <p className="text-sm text-gray-700">{transaction?.fiatAccountId}</p>
                        </div>
                    )
                }

                <div className="space-y-1">
                    <p className="text-md font-semibold">Rate</p>
                    <p className="text-sm text-gray-700">{transaction.rate}</p>
                </div >

                <div className="space-y-1">
                    <p className="text-md font-semibold">Date</p>
                    <p className="text-sm text-gray-700">{convertToIST(transaction?.date).date}</p>
                </div>
                <div className="space-y-1">
                    <p className="text-md font-semibold">Time</p>
                    <p className="text-sm text-gray-700">{convertToIST(transaction?.date).time}</p>
                </div>
            </div>
        </div>
    );
};

export default HistoryDetail;


