import React, { useEffect, useState } from 'react';
import { FaCheckCircle, FaClock, FaTimesCircle } from 'react-icons/fa';
import { Link } from 'react-router-dom';
import authFetch from '../utils/authFetch';
import { useNavigate } from 'react-router-dom';
import { convertToIST } from '../utils/dateTimeFormat';
const BuyHistory = () => {
    const navigate = useNavigate()
    const [buyHistoryTx, setBuyHistoryTx] = useState([])
    const [loading, setLoading] = useState(true)
    const getBuyHistory = async () => {
        try {
            const response = await authFetch('/api/v1/onramp/transaction', {
                method: 'GET',
            });

            if (response?.code === 200) {
                setBuyHistoryTx(response?.data);
                setLoading(false)
            } else {
                navigate('/login')
            }
        } catch (error) {
            console.error('Error fetching getBuyHistory:', error);
            navigate('/login')
        }
    };
    const getStatusIcon = (status) => {
        if (status === 'Success' || status === "SUCCESS") {
            return <FaCheckCircle className="text-green-500 text-xl" />;
        } else if (status === 'Pending' || status === 'PENDING' || status === 'FIAT_DEPOSIT_RECEIVED' || status === 'TRADE_COMPLETED' || status === 'ON_CHAIN_INITIATED') {
            return <FaClock className="text-yellow-500 text-xl" />;
        } else if (status === 'Failed' || 'FAILED') {
            return <FaTimesCircle className="text-red-500 text-xl" />;
        }
    };
    useEffect(() => {
        getBuyHistory()
    }, [])
    return (
        <div className="p-4 space-y-4 h-full">
            {!loading && buyHistoryTx.length === 0 ? (
                // If no transactions, show this message
                <div className="flex justify-center items-center mt-10">
                    <div className="bg-white p-6 shadow-lg rounded-lg text-center">
                        <p className="text-xl font-semibold text-gray-700">No Transactions Yet</p>
                        <p className="text-gray-500 mt-2 text-sm">
                            You haven't made any transactions yet. Once you make a transaction, your transaction history will be displayed here.
                        </p>
                        <Link to="/buy">
                            <button className="mt-4 bg-blue-600 text-white py-2 px-4 rounded-lg hover:bg-blue-700 transition duration-200">
                                Start Buying
                            </button>
                        </Link>
                    </div>
                </div>
            ) : (
                // Display transaction history if available
                <ul className="space-y-2">
                    {buyHistoryTx.map((transaction, index) => (
                        <Link
                            to={"/history-detail"}
                            state={{ transaction, transactionType: "buy" }}
                            key={index}  // Ensure key is at the root element in a list
                        >
                            <li className="flex justify-between items-center border-b py-4 px-4 hover:bg-gray-100 transition-colors duration-200 rounded-lg">
                                <div className="flex items-center space-x-4">
                                    {getStatusIcon(transaction?.status)}
                                    <div className="space-y-1">
                                        <p className="font-medium">
                                            {transaction?.transaction_id.length > 17
                                                ? `${transaction?.transaction_id.substring(0, 17)}...`
                                                : transaction?.transaction_id}
                                        </p>
                                        <p className="text-gray-500 text-sm">Status: {transaction?.status.charAt(0).toUpperCase() + transaction?.status.slice(1).toLowerCase()}</p>
                                    </div>
                                </div>
                                <div className="flex flex-col">
                                    <span className="text-gray-500 text-xs">
                                        {convertToIST(transaction?.date).date}
                                    </span>
                                    <span className="text-gray-500 text-xs">
                                        {convertToIST(transaction?.date).time}
                                    </span>
                                </div>
                            </li>
                        </Link>
                    ))}
                </ul>
            )}
        </div>
    );
};

export default BuyHistory;
